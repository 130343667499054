import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Main from "components/Layout/main";
import Image from "components/UI/image";

import "styles/faq-page.scss";

import searchIcon from "images/svg/search-icon.svg";
import StaticPageMeta from "../components/UI/staticPageMeta";

export const faqPageQuery = graphql`
  query FAQPageQuery {
    allContentfulFaqItem(sort: { fields: sortOrder }) {
      nodes {
        id
        name
        href
        image {
          title
          file {
            url
            contentType
          }
          gatsbyImageData(
            placeholder: NONE,
            formats: [AUTO, WEBP, AVIF],
          )
        }
        links {
          id
          text
          href
          isFrequentLink
          description {
            description
          }
        }
      }
    }
  }
`;

const FAQPage = (props) => {
    const queryData = useStaticQuery(faqPageQuery);
    const faqMainCategories = queryData.allContentfulFaqItem.nodes;

    const links = queryData.allContentfulFaqItem.nodes;
    const mostFrequentLink = [];
    const linksForSearch = [];
    links.forEach((category) => {
        const linksWithParentUrl = category.links.map((link) => {
            return { ...link, parentCategoryLink: category.href };
        });
        linksForSearch.push(...linksWithParentUrl);
        const frequentLinks = linksWithParentUrl.filter(
            (link) => link.isFrequentLink
        );
        mostFrequentLink.push(...frequentLinks);
    });
    mostFrequentLink.length = 5;

    const gtmData = {
        page: {
            title: "FAQ",
            type: "Static",
        },
    };

    return (
        <>
            <StaticPageMeta pageName="FAQ"/>
            <Main className="faq-wrapper" gtmData={gtmData}>
                <div className="faq">
                    <h1>Help center</h1>
                    <p className="title">
                        Everything you need to know about all the things
                    </p>
                    <label className="faq__heading--search-input-wrapper">
                        <input
                            className="search-input"
                            type="text"
                            placeholder="Some words"
                        />
                        <Image className="search-icon" src={searchIcon}/>
                    </label>
                    <div className="faq__content">
                        {faqMainCategories.map((item) => (
                            <Link to={`/faq${item.href}`}>
                                <div key={item.id} className="faq-category">
                                    <Image image={item.image} className="faq-category__image"/>
                                    <p className="faq-category__title">{item.name}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
                <div className="most-frequent-question-wrapper">
                    <div className="most-frequent-question">
                        <h2 className="most-frequent-question--title">
                            Frequently asked questions
                        </h2>
                        {mostFrequentLink.map((article) => (
                            <div className="article">
                                <Link to={`/faq${article.parentCategoryLink}${article.href}`}>
                                    <h4 className="article__title">{article.text}</h4>
                                </Link>
                                <p className="article__text">
                                    {article.description.description}
                                    <span>
                                        <Link to={`/faq${article.parentCategoryLink}${article.href}`}
                                              className="article__link">
                                            {" "}continue reading
                                        </Link>
                                    </span>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </Main>
        </>
    );
};

export default FAQPage;
